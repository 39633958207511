<template>
  <!--begin::Aside Secondary-->
  <div
    class="sidebar sidebar-left d-flex flex-row-auto flex-column"
    id="kt_sidebar"
    ref="kt_sidebar"
  >
    <!--begin::Aside Secondary Header-->
    <div
      class="sidebar-header flex-column-auto pt-5 pt-lg-19 pb-5 px-5 px-lg-10"
    >
      <!--begin::Toolbar-->
      <div class="d-flex">
        <!--begin::Desktop Search-->
        <div
          class="quick-search quick-search-inline flex-grow-1"
          id="kt_quick_search_inline"
        >
          <!--begin::Search Toggle-->
          <div
            id="kt_quick_search_toggle"
            data-toggle="dropdown"
            data-offset="0px,1px"
          ></div>
          <!--end::Search Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-anim-up"
          >
            <div
              class="quick-search-wrapper scroll"
              data-scroll="true"
              data-height="350"
              data-mobile-height="200"
            ></div>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Desktop Search-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Aside Secondary Header-->
    <!--begin::Aside Secondary Content-->
    <div class="sidebar-content flex-column-fluid pb-10 pt-9 px-5 px-lg-10">
      <!--begin: Stats Widget 19-->
      <div class="card card-custom bg-light-success card-shadowless gutter-b">
        <!--begin::Body-->
        <div class="card-body my-3">
          <a
            href="#"
            class="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block"
            >Package: {{ currentPackage.name }} </a
          >
          <div class="font-weight-bold text-muted font-size-sm">
            <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2"
              >{{ currentPercentUsages }}%</span
            >{{ currentUser.api_call_day }} / {{ currentPackage.api_call }} Per
            Day
          </div>
          <div class="progress progress-xs mt-7 bg-success-o-60">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="processStyle"
            ></div>
          </div>
        </div>
        <!--end:: Body-->
      </div>

      <div class="card card-custom bg-light-success card-shadowless gutter-b">
        <div class="card-body my-3">
          <a
            href="#"
            class="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block"
            >Package: {{ currentPackage.name }}</a
          >
          <div class="font-weight-bold text-muted font-size-sm">
            <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2"
              >{{ currentPercentUsagesm }}%</span
            >{{ currentUser.api_call }} / {{ currentPackage.api_call * 30 }} Per
            Month
          </div>
          <div class="progress progress-xs mt-7 bg-success-o-60">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="processStylem"
            ></div>
          </div>
        </div>
      </div>
      <!--end: Stats:Widget 19-->
    </div>
    <!--end::Aside Secondary Content-->
  </div>
  <!--end::Aside Secondary-->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTLayoutSidebar from "@/assets/js/layout/base/sidebar.js";
import { mapGetters } from "vuex";

export default {
  name: "sidebar",
  mounted() {
    this.$nextTick(() => {
      // Init sidebar
      KTLayoutSidebar.init(this.$refs["kt_sidebar"]);
    });
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentPackage",
      "currentPercentUsages",
      "currentPercentUsagesm",
    ]),
    processStyle() {
      return "width: " + this.currentPercentUsages + "%;";
    },
    processStylem() {
      return "width: " + this.currentPercentUsagesm + "%;";
    },
  },
};
</script>
